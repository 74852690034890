
@import "../../styles/common_styles.scss";

.menu-notification-element {
    margin: 2px;
    padding: 2px !important;
    border: 1px solid grey;
    border-radius: 2px;
    cursor: pointer;
    display: block;

    &:hover {
        @include standard-box-shadow;
    }

    h3 {
        margin: 0;
        font-size: 100%;
        font-weight: bold;
        white-space: normal;
    }

    p {
        margin: 0;
        font-size: 90%;
        white-space: pre-line;
    }

    h4 {
        margin: 0;
        font-size: 90%;
        font-style: italic;
    }
}
