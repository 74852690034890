
@import "../../styles/variables";
@import "../../styles/common_styles.scss";

.general-search-frontpage {
    // change the search bar position on the frontpage for small screens
    @media screen and (max-width: 395px) {
        position: absolute;
        width: 90%;
        margin: -56px 0 0 10px;

        form {
            input {
                position: relative;
                width: 100%;
                font-size: 100% !important;
            }
        }
    }
}

.general-search {
    width: 350px;

    @media #{$bootstrap-break-sm} {
        width: 280px;
    }

    input {
        @media #{$bootstrap-break-sm} {
            width: 280px;
            padding: 2px 0 !important;
            height: 22px !important;
        }

        @media screen and (max-width: 395px) {
            width: 215px;
            font-size: 70% !important;
        }
    }

    .search-results {
        position: absolute;
        background-color: white;
        border-radius: 0 0 $izele-border-radius $izele-border-radius;
        border: 1px solid $izele-green;
        width: 400px;

        & > div:first-child {
            padding: 5px 5px 0 5px;

            max-height: 400px;
            overflow-y: scroll;
        }

        .search-results-more {
            display: block;
            background-color: $izele-green;
            color: white;
            padding: 1px 5px;
            border-radius: 0 0 $izele-border-radius $izele-border-radius;

            &:hover {
                text-decoration: none;
                color: $izele-yellow;
            }
        }

        @media #{$bootstrap-break-xs} {
            width: 275px;

            .sr-pre-msg, .sr-loading {
                font-size: 80%;
                // below is to force message alignment
                position: relative;
                top: -3px;
            }

        }
    }
}
