$izele-green: rgb(0, 102, 51);
$izele-light-green-background: rgba(0, 102, 51, .2);
$izele-light-green-solid: rgb(204, 224, 214);

$izele-blue: #251F38;  // rgb(37, 31, 56)
$izele-yellow: #FFD900;

$standard-grey: #808080;
$standard-light-grey: #D0D0D0;
$standard-modal-background: rgba(50, 50, 50, .2);
$generic-link: rgb(51, 122, 183);

$bootstrap-break-xxs: "screen and (max-width: 320px)";
$bootstrap-break-xs: "screen and (max-width: 480px)";
$bootstrap-break-sm: "screen and (max-width: 768px)";
$bootstrap-break-md: "screen and (max-width: 992px)";
$bootstrap-break-lg: "screen and (max-width: 1200px)";

$bootstrap-gt-sm: "screen and (min-width: 768px)";
$bootstrap-gt-xs: "screen and (min-width: 480px)";

$izele-border-radius: 5px;

$standard-vertical-margin: 15px;
$standard-horizontal-margin: 10px;

@mixin standard-box-shadow() {
  box-shadow: 0 0 8px 1px rgba(0,0,0,0.75);
}

@mixin standard-text-shadow() {
  text-shadow: 0 0 8px rgba(0,0,0,0.75);
}

@mixin standard-text-shadow-svg() {
  filter:drop-shadow(0 0 8px rgba(0,0,0,0.75));
}

@mixin standard-text-shadow-white() {
  text-shadow: 0 0 8px rgba(255,255,255,0.75);
}

$bootstrap-grey-gradient-background: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);