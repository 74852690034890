
@import "../../styles/variables";

.usr-msg-overlay {
    position: absolute;
    z-index: 9999;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: $standard-modal-background;
}

.usr-msg-content {
    position: relative;
    background-color: white;
    width: 700px;
    margin: 200px auto 0 auto;
    padding: 15px;
    border-radius: $izele-border-radius;

    h2 {
        margin-bottom: 10px;
    }

    @media #{$bootstrap-break-sm} {
        width: 80%;
    }

    > svg {
        position: absolute;
        top: 12px; right: 15px;
        font-size: 180%;
        cursor: pointer;

        &:hover {
            color: $standard-light-grey;
        }
    }
}
