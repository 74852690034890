
@import "../../styles/common_styles.scss";

.menu-notifications {
    position: relative;  /* needed for bootstrap dropdown */
    top: 2px;

    svg {
        font-size: 130%;
    }

    & > div {
        position: relative;
        cursor: pointer;

        &:hover {
            color: #ffd900;
        }
    }

    .menu-notification-number {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        font-size: 90%;
        font-weight: bold;
        text-align: center;
        color: red;
    }
}


.menu-notification-viewed {
    h3, p, h4 {
        color: $standard-light-grey;
    }
}

