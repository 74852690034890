
@import "../../styles/variables";

.search-result-element {
    padding: 1px;
    height: 57px;
    margin: 0 0 8px 0;
    cursor: pointer;

    img {
        width: 55px;
        max-height: 55px;
        margin-right: 3px;
        float: left;
    }

    &:hover {
        @include standard-box-shadow;
        background-color: #EEE;
    }

    @media #{$bootstrap-break-xs} {
        height: 45px;

        img {
            width: 43px;
            height: 43px;
        }
    }
}

.sre-selected {
    @include standard-box-shadow;
    background-color: #EEE;
}

.sre-container {
    position: relative;
    margin: 0 0 0 58px;
    height: 55px;
    overflow-y: hidden;

    div:first-child {
        position: absolute;
        right: 3px; bottom: 2px; left: 0;

        div.sre-type {
            font-weight: bold;
            color: $standard-light-grey;
            font-size: 85%;
            position: absolute;
            right: 0;
            bottom: 1px;
        }

        i {
            float: left;
        }

        @media #{$bootstrap-break-xs} {
            i {
                font-size: 90%;
            }
        }

    }

    h4 {
        margin: 0;
    }

    @media #{$bootstrap-break-xs} {
        height: 45px;

        h4 {
            font-size: 100%;
            font-weight: bold;
        }
    }
}
