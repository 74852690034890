
.notification {
    border: 1px solid grey;
    margin: 0 0 8px 0;
    padding: 8px;

    h3 {
        margin: 0;
        font-size: 130%;
    }

    h4 {
        margin: 0;
        font-size: 100%;
        font-style: italic;
    }
}

.notification-controls {
    float: right;
    min-width: 20px;
    min-height: 20px;

    span {
        color: #006633;
        float: right;
        cursor: pointer;

        &:hover {
            color: #ffd900;
        }
    }

    svg {
        font-size: 120%;
    }
}
