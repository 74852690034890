@import "_variables.scss";

.small-grey-text {
  font-size: 90%;
  color: $standard-grey;
}

.grey-text {
  color: $standard-grey;
}

.iz-container {
  @media #{$bootstrap-break-sm} {
    padding: 0 4px;
    margin-top: -11px;
  }

  @media #{$bootstrap-break-xs} {
    margin-top: 0;  // hack as can't change body right now :/
  }
}

.it {
    font-style: italic;
}

.clear-link {
    color: inherit;

    &:hover {
        color: inherit;
    }
}

.loading-ellipsis {
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 2000ms infinite;
    content: "\2026";
    width: 0;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
}

.no-scroll {
  overflow: hidden;
}

.bd {
  font-weight: bold;
}
.it {
  font-style: italic;
}
.center {
  text-align: center;
}

.std-text-margin {
  margin-top: $standard-vertical-margin;
  margin-bottom: $standard-vertical-margin;
}



.bg-izele {
  background-color: rgba(0, 102, 51, .2);
}

.invisible-xs {
  @media #{$bootstrap-break-xs} {
    display: none !important;
  }
}

.visible-xxs {
  display: none !important;

  @media #{$bootstrap-break-xxs} {
    display: block !important;
  }
}


.visuallyhidden {
    /* elements hidden from sight, but still available to screen readers */
    position: absolute;
    left: -9999px;
}

[v-cloak] {
    display: none;
}

.iz-blue-text {
    color: #251F38;
}

.general-markdown-block {

  h1, h2, h3 {
    margin: 0 0 15px 0;
    font-weight: bold;
  }

  h1 {
    font-size: 130%;
  }

  h2 {
    font-size: 120%;
  }

  h3 {
    font-size: 100%;
  }

  p {
    margin: 0 0 15px 0;
  }

}


#main-tabs {
  margin: 0 0 15px 0;
  padding: 0;
  border: none;
  height: 41px;

  @media #{$bootstrap-break-xs} {
    height: 37px !important;
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
  }

  li {
    display: flex;
    align-items: center;
    float: left;
    //color: $izele-blue;
    color: $izele-green;
    margin: 0 30px 0 0;
    height: 100%;

    a {
      border: none;
      padding: 0;
      margin: 0;
      color: inherit;

      &:hover {
        background: none;
        color: $izele-blue;
        //color: $izele-green;
      }
    }

    &.active {
      font-weight: bold;
      //color: $izele-green;
      color: $izele-blue;
    }

    @media #{$bootstrap-break-xs} {
      margin: 0;
    }
  }

  svg {
    font-size: 250%;

    &.fa-newspaper {
      font-size: 270%;
    }

    &.fa-user-cog {
      font-size: 230%;
    }

    &.fa-iz-map {
      font-size: 240%;
    }

    @media #{$bootstrap-break-xs} {
      font-size: 200%;

      &.fa-user-cog {
        font-size: 190%;
      }

      &.fa-iz-map {
        font-size: 190%;
      }

      &.fa-newspaper {
        font-size: 220%;
      }
    }
  }
}

.main-tabs-disabled-tab {
    color: grey;
    opacity: .5;
}

// this is here as reused by photo-viewer.vue
.vue-modal-mask {
    position: fixed;
    overflow-x: hidden; overflow-y: scroll;
    z-index: 9980;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    padding: 5rem 0;
    transition: opacity .3s ease;
    display: flex;
    align-items: center;
}

.generic-cover {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .5);
    color: white;
    display: flex;
    align-items: center;
    justify-items: center;

    p {
        margin: 0 auto !important;
    }
}

.new-standard-layout {
    h1, h2, h3, h4, h5 {
        margin: 10px 0;
        font-weight: bold;
    }

    h3, h4, h5 {
        margin: 5px 0;
    }

    h1 {
        font-size: 2em;
        margin-top: 35px;
    }

    h2 {
        font-size: 1.71em;
        margin-top: 25px;
    }

    h3 {
        font-size: 1.29em;
    }

    h4 {
        font-size: 1.1em;
    }

    h5 {
        font-size: 1em;
    }

    p {
        margin-bottom: 10px;
    }

    .small-text {
        font-size: 0.9em;
    }

    label {
        font-weight: normal;
        margin: 0;
        width: 100%;

        &.left-checkbox {
            input[type=checkbox] {
                vertical-align: top;
                margin: 3px 2px 0 0;
            }

            // use span tag to float text to the right
            span {
                display: inline-block;
                max-width: 90%;
            }
        }
    }
}

.new-tab-pane {
    // updating bootstrap tab panes
    margin: 15px 0 50px 0;
}

.small-table {
    font-size: 0.9em;
    margin: 0;
}

.table-row-with-cover {
    // The <tr> should include this class, then somewhere inside create a div with the class "generic-cover" (class is in
    // this file). A <p> inside the div contains any required text.
    position: relative;

    .generic-cover {
        p {
            margin: auto;
            text-align: center;
            font-size: 110%;
        }
    }
}

// this was moved from left-bar-content.vue as useful elsewhere
// this size used for page sidebar icons, user icons in list of admins, etc
.medium-icon {
    width: 35px; height: 35px;
    display: inline-flex;
    border: 1px solid $standard-light-grey;
    border-radius: 3px;
    margin: 0 3px 3px 0;
    vertical-align: top;

    img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        border-radius: 3px;
    }
}

.caret {
    // copied from bootstrap
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 4px;
    border-left: 4px solid transparent;
}

.table-no-inner-borders {
    // todo: temp until about tabs upgraded to new style
    tr {
        td {
            border: none !important;
            height: 40px !important;
        }
    }

    tr {

    }
}